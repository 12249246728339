// COLORS

$primary900: #044e54;
$primary800: #0a6c74;
$primary700: #0e7c86;
$primary600: #14919b;
$primary500: #2cb1bc;
$primary400: #38bec9;
$primary300: #54d1db;
$primary200: #87eaf2;
$primary100: #bef8fd;
$primary000: #e0fcff;

$neutral900: #27241d;
$neutral800: #423d33;
$neutral700: #504a40;
$neutral600: #625d52;
$neutral500: #857f72;
$neutral400: #a39e93;
$neutral300: #b8b2a7;
$neutral200: #d3cec4;
$neutral100: #e8e6e1;
$neutral000: #faf9f7;

$info900: #003e6b;
$info800: #0a558c;
$info700: #0f609b;
$info600: #186faf;
$info500: #2680c2;
$info400: #4098d7;
$info300: #62b0e8;
$info200: #84c5f4;
$info100: #b6e0fe;
$info000: #dceefb;

$error900: #610404;
$error800: #780a0a;
$error700: #911111;
$error600: #a61b1b;
$error500: #ba2525;
$error400: #d64545;
$error300: #e66a6a;
$error200: #f29b9b;
$error100: #facdcd;
$error000: #ffeeee;

$warning900: #513c06;
$warning800: #7c5e10;
$warning700: #a27c1a;
$warning500: #c99a2e;
$warning400: #e9b949;
$warning300: #f7d070;
$warning200: #f9da8b;
$warning100: #f8e3a3;
$warning000: #fcefc7;
$warning900: #fffaeb;

$success900: #014d40;
$success800: #0c6b58;
$success700: #147d64;
$success600: #199473;
$success500: #27ab83;
$success400: #3ebd93;
$success300: #65d6ad;
$success200: #8eedc7;
$success100: #c6f7e2;
$success000: #effcf6;
